import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.png'


import { onMounted, ref } from 'vue'
import Api from '@/api'
import { domain } from '@/composables/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const show = ref(false)
let user = ref('')

onMounted(async () => {
  await checkAuth()
})

async function exit() {
  try {
    const res = await Api.logout()
    if (res.status === 200) {
      window.location.href = `https://sso.${domain()}`
    }
  } catch (e) {
    console.error(e)
  }
}
async function checkAuth(): Promise<void> {
  try {
    const res = await Api.me()
    if (res.status === 200) {
      user.value = res.data.user.name
      show.value = true
    } else window.location.href = `https://sso.${domain()}`
  } catch (e) {
    console.error(e)
    window.location.href = `https://sso.${domain()}`
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo" }, [
        _createElementVNode("img", { src: _imports_0 })
      ], -1)),
      _createElementVNode("div", null, _toDisplayString(_unref(user)), 1),
      _createElementVNode("button", { onClick: exit }, "Quit")
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})