<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Api from '@/api'
import { domain } from '@/composables/common'

const show = ref(false)
let user = ref('')

onMounted(async () => {
  await checkAuth()
})

async function exit() {
  try {
    const res = await Api.logout()
    if (res.status === 200) {
      window.location.href = `https://sso.${domain()}`
    }
  } catch (e) {
    console.error(e)
  }
}
async function checkAuth(): Promise<void> {
  try {
    const res = await Api.me()
    if (res.status === 200) {
      user.value = res.data.user.name
      show.value = true
    } else window.location.href = `https://sso.${domain()}`
  } catch (e) {
    console.error(e)
    window.location.href = `https://sso.${domain()}`
  }
}
</script>

<template>
  <nav>
    <div class="logo">
      <img src="@/assets/img/logo.png" />
    </div>
    <div>{{ user }}</div>
    <button @click="exit">Quit</button>
  </nav>
  <router-view />
</template>

<style lang="scss">
nav {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .logo {
    width: 70px;
    img {
      width: 100%;
    }
  }
}
</style>
