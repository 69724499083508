<template>
  <div class="home">Welcome to MrBrownie</div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.home {
  text-align: center;
}
</style>
