import axios, { AxiosResponse } from 'axios'
import { domain } from '@/composables/common'
axios.defaults.withCredentials = true

// interface RegistrationData {
//   email: string;
//   name: string;
//   password: string;
// }

export default {
  async logout(): Promise<AxiosResponse> {
    return axios.post(`https://sso.${domain()}/api/v1/auth/logout`)
  },
  async me(): Promise<AxiosResponse> {
    return axios.get(`https://sso.${domain()}/api/v1//auth/me`)
  },
}
